<template>
    <div class="card card-primary card-outline">
        <div class="card-header">
            <div class="card-title">
                <i class="fas fa-car"></i> Fahrzeuge
            </div>
            <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                <ul class="navbar-nav">
                    <li class="nav-item d-none d-md-block">
                        <!-- <button type="button" class="btn btn-sm btn-secondary" data-widget="control-sidebar"><i class="fas fa-filter"></i></button> -->
                        <router-link class="btn btn-primary btn-sm mr-1" :to="{name: 'cars.create'}" v-if="$auth.check('articles.create')"><i class="fas fa-plus-circle"></i> Neues Fahrzeug</router-link>
                    </li>
                </ul>

            </nav>
        </div>
        <div class="card-body">
            <table class="table table-hover table-sm">
                <thead>
                    <th>Nr.</th>
                    <th>Name</th>
                    <th>Kennzeichen</th>
                    <th>Aktionen</th>
                </thead>
                <tbody>
                    <tr v-for="(car, index) in cars" :key="car.id">
                        <td>{{index+1}}</td>
                        <td>{{car.name}}</td>
                        <td>{{car.plate}}</td>
                        <td>
                            <div class="card-tools">
                                <router-link v-b-popover.hover.bottom="'bearbeiten'" class="mr-1 btn btn-warning btn-xs" :to="{name: 'cars.details', params: {id: car.id}}" v-if="$auth.check('car.edit')"><i class="fas fa-fw fa-edit"></i></router-link>
                                <b-button v-b-popover.hover.bottom="'löschen'" size="xs" @click="deleteCar(car.id)" variant="danger" v-if="$auth.check('car.destroy')"><i class="fas fa-fw fa-trash"></i></b-button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CarsIndex',
    title: 'Fahrzeuge',

    data()
    {
        return {
            cars: []
        }
    },

    methods: {
        loadCars()
        {
            this.axios
                .get('/cars')
                .then((response) => {
                    this.cars = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    });
                })
        },

        deleteCar(car)
        {
            console.log(car);
            this.axios
                .delete("/cars/" + car)
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Fahrzeug gelöscht.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadCars();
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen.'
                    })
                })
        }
    },

    created() {
        this.loadCars();
    }
}
</script>

<style>

</style>